<template>
  <screen_width_cont>
    <div class="cont_300_basico ancho_acotado">
      <div class="modalCotizadorEtapas">
        <div class="modalCotizadorEtapasCard">
          <div class="modalCotizadorEtapasLogo">
            <img :src="logo" alt="" srcset="" />
          </div>

          <div class="modalCotizadorEtapasText1 text_basico f_w_500">
            {{ t("app.cotizadorEtapas.tit") }}
          </div>

          <div class="modalCotizadorEtapasText2">
            {{ t("app.cotizadorEtapas.sub_tit_1") }}
          </div>

          <div class="modalCotizadorEtapasItems">
            <div class="modalCotizadorEtapasItem">
              <div class="modalCotizadorEtapasItemImg">
                <img :src="iconCotiEtapasUno" alt="" srcset="" />
              </div>
              <div class="modalCotizadorEtapasItemText">
                {{ t("app.cotizadorEtapas.text_1") }}
              </div>
            </div>
            <div class="modalCotizadorEtapasItem">
              <div class="modalCotizadorEtapasItemImg">
                <img :src="iconCotiEtapasDos" alt="" srcset="" />
              </div>
              <div class="modalCotizadorEtapasItemText">
                {{ t("app.cotizadorEtapas.text_2") }} &nbsp;
                <span>{{ remitenteNombre }}</span
                >.
              </div>
            </div>
            <div class="modalCotizadorEtapasItem">
              <div class="modalCotizadorEtapasItemImg">
                <img :src="iconCotiEtapasTres" alt="" srcset="" />
              </div>
              <div class="modalCotizadorEtapasItemText">
                {{ t("app.cotizadorEtapas.text_3") }} &nbsp;
                <span>{{ destinatarioNombre }}</span
                >.
              </div>
            </div>
          </div>

          <div class="modalCotizadorEtapasBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.entiendo')"
              @action="continuar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>

          <div class="modalCotizadorEtapasEtapas">
            <pasosGiro paso="5" totales="6" />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>


<script>
// import { IonContent, IonPage,  } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import { mapMutations, mapState } from "vuex";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorResumen",
  components: {
    btnAction,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      logo: require("../assets/imgs/logo.svg"),
      iconCotiEtapasUno: require("../assets/imgs/iconCotiEtapasUno.svg"),
      iconCotiEtapasDos: require("../assets/imgs/iconCotiEtapasDos.svg"),
      iconCotiEtapasTres: require("../assets/imgs/iconCotiEtapasTres.svg"),
    };
  },
  computed: {
    ...mapState(["remitente", "transaccion", "widthScreen"]),
    destinatarioNombre() {
      let aux = "";
      if (this.remitente && this.transaccion && this.transaccion.id_persona) {
        aux = `${
          this.remitente.destinatarios[this.transaccion.id_persona].nombre1
        } ${
          this.remitente.destinatarios[this.transaccion.id_persona].apellido1
        } ${
          this.remitente.destinatarios[this.transaccion.id_persona].apellido2
        }`;
      }
      return aux;
    },
    remitenteNombre() {
      let aux = "";
      if (this.remitente) {
        aux = `${this.remitente.nombre1} ${this.remitente.nombre2} ${this.remitente.apellido1} ${this.remitente.apellido2}`;
      }
      return aux;
    },
    estadoBtn() {
      let aux = "active";
      // if(!this.errorSimulador){
      //   aux = "active";
      // }
      return aux;
    },
  },
  methods: {
    ...mapMutations(["setshowSpinner"]),

    continuar() {
      this.setshowSpinner(false);
      this.$router.push({ name: "cotizadorDatos", replace: true });
      // modalController.dismiss({ dismissed: true });
    },
  },
  mounted() {
    // console.log(this.transaccion);
  },
};
</script>

<style>
.modalCotizadorEtapas {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  height: 100vh;
}
.modalCotizadorEtapasCard {
  background: #ffffff;
  box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 1.5rem 1.125rem 1.5rem 1.125rem;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.modalCotizadorEtapasLogo {
  text-align: center;
}
.modalCotizadorEtapasLogo img {
  width: 9.6rem;
}
.modalCotizadorEtapasText1 {
  margin-top: 1.4375rem;
  text-align: center;
  color: #787878;
  padding-top: 2rem;
}
.modalCotizadorEtapasText2 {
  margin-top: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #787878;
}
.modalCotizadorEtapasItems {
  padding-top: 4rem;
  padding-bottom: 3rem;
}
.modalCotizadorEtapasItem {
  margin-top: 0.9rem;
  display: flex;
  justify-content: flex-start;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #787878;
}
.modalCotizadorEtapasItemImg {
  min-width: 1.375rem;
  min-height: 1.375rem;
  max-width: 1.375rem;
  max-height: 1.375rem;
}
.modalCotizadorEtapasItemImg img {
  width: 100%;
}
.modalCotizadorEtapasItemText {
  padding-left: 1rem;
}
.modalCotizadorEtapasItemText span {
  font-weight: 700;
  color: #4c4c4c;
}
.modalCotizadorEtapasBtn {
  margin-top: 1.7rem;
}
.modalCotizadorEtapasEtapas {
  margin-top: 0.7rem;
}

/* ////////////////-------------------------------------/////////// */
/* @media (min-width: 600px) {
  .modalCotizadorEtapasCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .modalCotizadorEtapasText1 {
    padding-top: 2rem;
  }
  .modalCotizadorEtapasText2 {
    padding-top: 0rem;
  }
  .modalCotizadorEtapasItems {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
  .modalCotizadorEtapasBtn {
    margin-top: 3.7rem;
  }
} */
</style>